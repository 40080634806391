import * as React from "react"

const SvgComponent = (props) => (
  <svg
    style={{
      enableBackground: "new 0 0 40 40",
    }}
    viewBox="0 0 40 40"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#FFFFFF" d="M37.1 5.22h-2v-2c0-.5-.4-.9-.9-.9s-.9.4-.9.9v2h-2c-.5 0-.9.4-.9.9s.4.9.9.9h2v2c0 .5.4.9.9.9s.9-.4.9-.9V7h2c.5 0 .9-.4.9-.9s-.4-.9-.9-.9v.02zM28.76 20a6.714 6.714 0 0 0 1.16-9.42A6.688 6.688 0 0 0 24.76 8c-1.64 0-3.22.62-4.42 1.74-2.12-3.94-7.02-5.41-10.96-3.29S3.97 13.47 6.09 17.4c.65 1.2 1.59 2.23 2.74 2.97C4.87 21.53 2 24.85 2 28.77v8c0 .5.4.9.9.9h20.88c.5 0 .9-.4.9-.9v-1.62h8.54c.5 0 .9-.4.9-.9V27.3a7.79 7.79 0 0 0-5.36-7.3zm-4-10.25a4.903 4.903 0 0 1 5 4.8 4.903 4.903 0 0 1-4.8 5c-1.79.04-3.46-.91-4.35-2.46l.05-.14c.08-.17.15-.34.22-.51s.13-.4.19-.6.05-.16.07-.25c.17-.64.26-1.29.26-1.95 0-.43 0-.84-.06-1.26a6.57 6.57 0 0 0-.16-.77v-.11c.89-1.1 2.22-1.74 3.63-1.75h-.05zM19.41 21c-.52-.24-1.05-.44-1.6-.59a7.8 7.8 0 0 0 1.8-1.64c.39.51.86.95 1.39 1.32-.54.24-1.04.56-1.49.93l-.1-.02zM7 13.64c-.01-3.47 2.81-6.29 6.28-6.3 2.69 0 5.09 1.7 5.96 4.25.24.66.36 1.35.36 2.05-.01.68-.12 1.36-.34 2-.79 2.34-3.06 4.28-5.62 4.28h-.74A6.3 6.3 0 0 1 7 13.64zm15.88 22.27H3.8v-7.14c0-1.46.54-2.88 1.46-4.01.97-1.19 2.31-2.05 3.77-2.53.89-.29 1.83-.41 2.76-.45.96-.04 1.95-.1 2.91-.02.5.04 1 .14 1.49.24.52.11 1.03.24 1.53.39.25.07.49.16.74.24.72.25 1.36.62 1.97 1.08.38.29.66.63.99.96.27.27.48.67.64 1.01.26.53.38 1.11.51 1.68.22.99.32 1.99.32 3.01v5.58l-.01-.04zm9.44-2.56h-7.64V28.4c-.1-1.57-.61-3.09-1.47-4.4-.05-.07-.11-.14-.16-.22a9.66 9.66 0 0 0-.57-.7l-.05-.06c-.35-.38-.75-.73-1.18-1.02a4.6 4.6 0 0 1 2.61-.65h2.22a6.118 6.118 0 0 1 6.24 5.95v6.05z" />
  </svg>
)

export default SvgComponent
